<template>
  <div>
    <template v-show="built">

     <v-col cols="12">
       <SquareCheckout
       />
        <v-checkbox
          v-model="saveCard"
          label="Save Card"
        ></v-checkbox>

      </v-col>
    <div class="text-center">
      <v-col center cols="12">
        <v-btn 
          :loading="isLoading"
          :disabled="isLoading"
          color="primary" large
          @click="requestCardNonce($event)"
          >
          {{ buttonText }}
        </v-btn>
      </v-col>
    </div>
    </template>
  </div>
</template>

<script>
import merge from 'merge'
//import 'es6-promise/auto'
import axios from 'axios'
import { mapMutations, mapGetters } from 'vuex'
export default {
  data: function() {
    return {
      saveCard: false,
      errors: null,
      masterpass: false,
      loading: false,
      applePay: false,
      loader: null,
      built: false
    };
  },
  watch: {
    showPaymentForm: function() {
        if (!this.showPaymentForm) {
            return 1;
        }
        if (SqPaymentForm.isSupportedBrowser() && !this.built) {
            this.loading = true
            this.paymentForm.build();
            this.paymentForm.recalculateSize();
            this.built = true
            this.loading = false
        }
    },
    loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 3000)
        this.loader = null
      }
  },
  props: {
    showPaymentForm: Boolean,
    buttonText: String,
    id: Number,
  },
  mounted: function() {

  //   document.getElementById("sq-ccbox").setAttribute("id","sq-ccbox" + this.productId);
  //   document.getElementById("sq-card-number").setAttribute("id", "sq-card-number" + this.productId)
  //   document.getElementById("sq-expiration-date").setAttribute("id", "sq-expiration-date" + this.productId)
  //   document.getElementById("sq-postal-code").setAttribute("id", "sq-postal-code" + this.productId)
  //   document.getElementById("card-nonce").setAttribute("id", "card-nonce" + this.productId)

        this.injectCheckoutScript()
            .then(() => this.configureSquare())
            .catch(e => console.error(e));
  },
  computed: {
    idccbox () {
      this.productId + "ccbox"
    },
    idcard () {
      this.productId + "card"
    },
    idexpiration () {
      this.productId + "expiration"
    },
    idcvv () {
      this.productId + "cvv"
    },
    idpostal () {
     this.productId + "postal" 
    },
    idnonce () {
      this.productId + "nonce"
    },
    isLoading () {  //computed property merges local loading and state.loading
      return this.loading || this.$store.state.loading
    }
  },
  methods: {
    // ...mapMutations([
    //   'recordPayment',
    // ]),
    ...mapGetters([
      'cart',
    //    'cart2',
      'userInfo',
    ]),
    requestCardNonce (event) {
      // Don't submit the form until SqPaymentForm returns with a nonce
      event.preventDefault()
      this.errors = null //clear errors in case this is not the first attempt
      this.loading = true
      // Request a nonce from the SqPaymentForm object
      this.paymentForm.requestCardNonce()
    },
    injectCheckoutScript() {
        let self = this
        let el = document.createElement('SCRIPT')
        let ctr = 0
        let scriptSource = 'https://js.squareup.com/v2/paymentform.js'
        let scripts = document.getElementsByTagName('script');
        let scriptExists = false
        for (var i in scripts){
            if (scripts[i].src == scriptSource) {
                scriptExists = savetrue
            }
        }
        el.setAttribute('src', scriptSource);
        if(!scriptExists) {
         //   document.getElementById(this.idccbox).appendChild(el)
            document.querySelector("#" + "sq-ccbox").appendChild(el)
        }
        return new Promise((resolve, reject) => {
            let handle = window.setInterval(function () {
                if (window.SqPaymentForm) {
                    self.loaded = true
                    resolve()
                    clearInterval(handle)
                }
                ctr++
                if (ctr > 1000) {
                    reject("Unable to load paymentform.js")
                    clearInterval(handle)
                }
            }, 5)
        })
    },
    configureSquare () {
        let locationId = ENV.squareLocationId(this.$store.getters.isAdmin); 
        let applicationId = ENV.squareApplicationId(this.$store.getters.isAdmin); //FIREBASE.auth.currentUser.uid
        let that = this;
        this.paymentForm = new SqPaymentForm({
        autoBuild: false,
        applicationId: applicationId,
        locationId: locationId,
        inputClass: "sq-input",
        // Initialize the payment form elements
        // Customize the CSS for SqPaymentForm iframe elements
        inputStyles: [{
        //    fontSize: '14px',
        //    fontFamily: 'Helvetica Neue',
            padding: '12px',
         //   color: '#ffb3b3',//'#373F4A',
            backgroundColor: 'transparent',
            lineHeight: '20px',
        //    placeholderColor: '#CCC',
            _webkitFontSmoothing: 'antialiased',
            _mozOsxFontSmoothing: 'grayscale'
        }],
        applePay: false,
        masterpass: false,
        cardNumber: {
            elementId: that.id + "-sq-card-number",
           // elementId: this.idcard,
            placeholder: "Card number"
        },
        cvv: {
            elementId: that.id + "-sq-cvv",
          //  elementId: this.idcvv,
            placeholder: "CVV"
        },
        expirationDate: {
            elementId: that.id + "-sq-expiration-date",
          //  elementId: this.idexpiration,
            placeholder: "MM / YY"
        },
        postalCode: {
            elementId: that.id + "-sq-postal-code",
          //  elementId: this.idpostal,
            placeholder: "Zip Code"
        },
        // SqPaymentForm callback functions
        callbacks: {
            methodsSupported: function(methods) {
                that.applePay = methods.applePay;
                that.masterpass = methods.masterpass;
            },
            /*
            * Digital Wallet related functions
            */
            createPaymentRequest: function() {
                var paymentRequestJson;
                /* ADD CODE TO SET/CREATE paymentRequestJson */
                return paymentRequestJson;
            },
            validateShippingContact: function(contact) {
                var validationErrorObj;
                /* ADD CODE TO SET validationErrorObj IF ERRORS ARE FOUND */
                return validationErrorObj;
            },
            /*
            * callback function: cardNonceResponseReceived
            * Triggered when: SqPaymentForm completes a card nonce request
            */
            cardNonceResponseReceived: function(errors, nonce, cardData) {
                let self = that
                if (errors) {
                    errors.forEach(function(error) {
                      if(self.errors == null)
                        self.errors = []
                      self.errors.push(error.message);
                      self.loading = false
                    });
                    return;
                }
                // Assign the nonce value to the hidden form field
                document.getElementById("card-nonce").value = nonce;
              //  console.log(nonce)
                self.submitPayment(nonce)
            },
            /*
            * callback function: paymentFormLoaded
            * Triggered when: SqPaymentForm is fully loaded
            */
            paymentFormLoaded: function() {
                console.log("paymentFormLoaded");
                this.loading = false
                /* HANDLE AS DESIRED */
            }
        }
        });
    },
    submitPayment (nonce) { //charges entered cc for whatever is in the cart
      let self = this
      const functionServer = ENV.functionsServer()
      return new Promise( function(resolve) {
        axios.post(functionServer + "purchasecart", 
        {nonce: nonce, order: self.cart(), cart: self.cart2(), customer: self.userInfo()})
          .then( function(json) {
            self.loading = false
        //    self.recordPayment(json)
            self.$emit('payment-successful')
            // The data from the request is available in a .then block
            // We return the result using resolve.
            
            resolve(json);
          })
          .catch( function(err) {
            if(self.errors == null)
              self.errors = []
              self.errors.push("Payment Error: Payment Not Processed");
              self.loading = false
          });
      });     
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}

body, html {
  color: #373F4A;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
}

button {
  border: 0;
}

hr {
  height: 1px;
  border: 0;
  background-color: #CCC;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.third {
  float:left;
  width: calc((100% - 24px) / 3);
  padding: 0;
  margin: 0 12px 12px 0;
}

.third:last-of-type {
  margin-right: 0;
}

/* Define how SqPaymentForm iframes should look */
.sq-input {
  box-sizing: border-box;
  border: 1px solid #E0E2E3;
  border-radius: 4px;
  outline-offset: -2px;
  display: inline-block;
  -webkit-transition: border-color .2s ease-in-out, background .2s ease-in-out;
     -moz-transition: border-color .2s ease-in-out, background .2s ease-in-out;
      -ms-transition: border-color .2s ease-in-out, background .2s ease-in-out;
          transition: border-color .2s ease-in-out, background .2s ease-in-out;
}

/* Define how SqPaymentForm iframes should look when they have focus */
.sq-input--focus {
  border: 1px solid #4A90E2;
  background-color: rgba(74,144,226,0.02);
}


/* Define how SqPaymentForm iframes should look when they contain invalid values */
.sq-input--error {
  border: 1px solid #E02F2F;
  background-color: rgba(244,47,47,0.02);
}

#sq-card-number {
  margin-bottom: 8px;
}

/* Customize the "Pay with Credit Card" button */
.button-credit-card {
  width: 100%;
  height: 56px;
  margin-top: 10px;
  background: #4A90E2;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
  -webkit-transition: background .2s ease-in-out;
     -moz-transition: background .2s ease-in-out;
      -ms-transition: background .2s ease-in-out;
          transition: background .2s ease-in-out;
}

.button-credit-card:hover {
  background-color: #4281CB;
}


#error {
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  color: red;
  font-weight: 500;
  text-align: center;
  opacity: 0.8;
}
</style>